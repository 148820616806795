<div class="container p-5">
  <div class="row">
    <div class="col-md-8 text-center mx-auto">
      <h3 class="display-2">Jugendfreizeit 2022</h3>
      <p class="lead">
        Du bist in der Jugend oder wurdest eingeladen? Du bist mindestens 14 und hast Lust 10 Tage in Österreich zu
        verbringen und Gottes Wort besser kennenzulernen?
      </p>
      <p class="lead">Dann bist du hier genau richtig!</p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-6 mx-auto" *ngIf="shortOverview">
      <h5 *ngFor="let shortFeatures of shortOverview.features" class="mt-3 mb-3 row">
        <span class="col-12">
          <i class="fas fa-{{ shortFeatures.icon }} mr-3"></i>
          {{ shortFeatures.content }}
        </span>
      </h5>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-6 mx-auto">
      <p>
        Bis Mitte Mai sind die Plätze erstmal für die Jugend / Mitglieder der Christlichen Versammlung reserviert. Alle
        „externen“ Anmeldungen in dieser Zeit werden vorerst auf der Warteliste geführt
      </p>
    </div>
  </div>
  <div class="row mt-3">
    <div class="col-md-12">
      <h3 class="mt-2">Wichtige Informationen</h3>
      <ul class="list-group list-group-flush">
        <li class="list-group-item">
          1. Überweise bitte die Anzahlung <br />
          <strong
            >150,00€ - auf das folgende Konto: <br />
            Kontoinhaber: Tim Bochenek <br />
            IBAN: DE83 7609 0500 0003 9329 82 <br />
            Betreff: Jugendfreizeit 2022 + Vor- und Nachname des Teilnehmers <br />
          </strong>
          <br />
          Erst mit dem Erhalt der Anzahlung zählst du als verbindlich angemeldet. Falls du aus verschiedenen Gründen
          doch nicht mit auf die Freizeit kannst, wird der Betrag nur erstattet, wenn du eine Person als Ersatz für
          deinen Teilnehmerplatz findest. Der restliche Freizeitbetrag wird erst im Sommer fällig. Dazu gibt es
          gesondert eine Mail. Wir rechnen mit einem gesamten Freizeitbetrag von maximal 500,00€. Die Chancen stehen
          gut, dass es etwas billiger werden kann.
        </li>
        <li class="list-group-item">
          2. Falls du noch nicht volljährig bist, musst du noch einen Zettel von deinen Eltern ausfüllen lassen.<br /><br />
          <strong><a href="/assets/anmeldung.pdf" target="blank">Hier gehts zum Elternzettel! </a></strong><br /><br />
          Diesen Zettel bitte ausgefüllt,
          <ul>
            <li>a. Eingescannt an <a href="mailto:tim.bochenek@web.de">tim.bochenek@web.de</a> zurückschicken</li>
            <li>b. oder bei Tim persönlich abgeben</li>
          </ul>
        </li>
        <li class="list-group-item">3. Weitere Informationen folgen Mitte Juni.</li>
        <li class="list-group-item">4. Bei Fragen kannst du einfach Michel anrufen: +49 176 84582373</li>
      </ul>
    </div>
  </div>
</div>
