<footer class="footer">
  <div class="container">
    <div class="row align-items-center justify-content-md-between">
      <div class="col-md-6">
        <div class="copyright">
          © 2022
          <a href="https://roser.dev" target="_blank"> Jonas Roser </a>
        </div>
      </div>
      <div class="col-md-6">
        <ul class="nav nav-footer justify-content-end">
          <li class="nav-item">
            <a class="nav-link" href="http://www.cv-schwabach.de" target="_blank"> CV Schwabach </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://cv-schwabach.de/impressum/" target="_blank"> Impressum </a>
          </li>
          <li class="nav-item">
            <a class="nav-link" href="https://cv-schwabach.de/datenschutzerklaerung/" target="_blank">
              Datenschutzerklärung
            </a>
          </li>
        </ul>
      </div>
    </div>
  </div>
</footer>
