import { enableProdMode } from "@angular/core"
import { platformBrowserDynamic } from "@angular/platform-browser-dynamic"
import * as Sentry from "@sentry/angular"
import { BrowserTracing } from "@sentry/tracing"

import { AppModule } from "./app/app.module"
import { environment } from "./environments/environment"

if (environment.production) {
  enableProdMode()
}

Sentry.init({
  dsn: "https://fc5f63b7afff48ca91ff3714375d0522@o477951.ingest.sentry.io/6371877",
  integrations: [
    new BrowserTracing({
      tracingOrigins: ["localhost", "https://austria.roser.dev"],
      routingInstrumentation: Sentry.routingInstrumentation,
    }),
  ],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: 1.0,
})

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch((err) => console.error(err))
